@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@500&display=swap");
* {
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
  /* overflow: hidden; */
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.2 !important;
}
.btn:focus {
  box-shadow: none;
}
.btn-toggle {
  border: none;
  outline: none !important;
  box-shadow: none !important;
}

/* Common style start here */

/* Scrollbar style start here */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bebff1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #797bc6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e5fa8;
}
/* Scrollbar style end here */

body {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  line-height: 25px;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="date"] {
  padding: 20px;
  font-family: "Lato", sans-serif;
  border: solid 1px #4d4e8d;
  border-radius: 0;
  height: 60.8px;
  width: 100%;
}

/* Header Style start here */
.header {
  background: #ffffff;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.0585664);
  margin-bottom: 2px;
  max-height: 90px;
  position: fixed;
  width: 100%;
  z-index: 1073;
}

.navbar-brand {
  margin: 0 20px;
}

.header-nav-wrap {
  padding: 0;
  z-index: 1;
}

.custom-arrow-dropdown a {
  padding: 0;
}
.custom-arrow-dropdown .dropdown-item {
  padding: 4px 10px;
}
.custom-arrow-dropdown a:after {
  display: none;
}
.login-user-info {
  border-left: 1px #eeeeee solid;
  /* padding: 16px 20px; */
  padding: 0 20px;
}

.login-user-info h6 {
  font-size: 10px;
  font-weight: 400;
  color: #7fb4f2;
}

.login-user-info p {
  font-size: 13px;
  font-weight: 400;
}

.login-user-info .dropdown-toggle::after {
  display: none;
}

/* sidebar wrap style start here */
.admin-wrap {
  background: #f0f3f8b9;
  display: flex;
  height: 100%;
  /* padding-top: 75px; */
  padding-top: 50px;
}

.sidebar-nav-wrap {
  min-width: 250px;
  min-height: calc(100vh - 50px);
  background: #4d4e8d;
  max-height: 90vh;
  overflow-y: auto;
  position: fixed;
  left: 0;
}
/* .sidebar-nav-wrap.toggle .sb-nav li a:before {
  background: none;
} */
.toggle {
  min-width: 100px !important;
  overflow-x: hidden;
}

.toggle .sb-nav li span {
  display: none;
}

.toggle .sb-nav li a:after {
  display: none;
}

.sb-nav {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.sb-nav li {
  margin: 10px 0;
}

.sb-nav li a {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 17px 10px 17px 30px;
  border-left: 7px transparent solid;
  position: relative;
}

.sb-nav li a img {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
}

.sb-nav li.active a,
.sb-nav li a:hover {
  border-left-color: #8ab6a1;
}

.sb-nav li.active a:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../img/menu-active-arrow.svg) no-repeat center center;
  position: absolute;
  right: 10px;
  top: 18px;
}
.sb-nav .submenu-wrap li.active a:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../img/menu-active-arrow.svg) no-repeat center center;
  position: absolute;
  right: 10px;
  top: 10px !important;
}

.main-menu {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 17px 10px 10px 30px;
  border-left: 7px transparent solid;
  position: relative;
}
.main-menu:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../img/down-arrow.svg) no-repeat center center;
  filter: invert(100%);
  position: absolute;
  right: 10px;
  top: 16px !important;
}
.submenu-wrap {
  background: #5d5ea9;
}
.submenu-wrap .sub-menu a {
  padding-left: 60px !important;
}
.main-menu {
  cursor: pointer;
}
.main-menu img {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
}
.sb-submenu-icn {
  width: 18px;
  height: auto;
  margin-right: 12px !important;
}
.main-menu a {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 17px 10px 0px 30px;
  border-left: 7px transparent solid;
  position: relative;
}
.sub-menu {
  text-align: start;
  margin: 0 !important;
}
.sub-menu a {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 10px 10px 10px 30px !important;
  border-left: 7px transparent solid;
  position: relative;
}
/* .sb-nav li:last-child a {
  margin-top: 100px;
}

.sb-nav li:last-child a:before {
  content: "";
  width: 85%;
  height: 1px;
  background: #eeeeee;
  position: absolute;
  left: 15px;
  top: -25px;
} */

/* Content wrap style start here */
.admin-content-wrap {
  width: 100%;
}

.title-wrap {
  background: #ffffff;
  padding: 15px 40px;
  position: relative;
  /* z-index: 1072 !important; */
}

.page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #4d4e8d;
}

.sub-title {
  font-size: 18px;
  font-weight: 500;
  background: #6a7c96;
  padding: 14px 30px;
  color: #ffffff;
  margin-bottom: 0;
}

.filter-wrap {
  background: #ffffff;
  padding: 15px;
}

.search-input {
  background: url(../img/search-icn.svg) no-repeat 95% center #ffffff;
  border: 1px solid #4d4e8d;
  min-width: 380px;
  padding: 15px 30px;
}

.btn-filter {
  background: transparent;
  border: none;
  padding: 15px;
}
.react-select-3-listbox {
  z-index: 999 !important;
}
.tox-tinymce {
  z-index: 1 !important;
}

.btn-green {
  background: #8ab6a1 !important;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #ffffff !important;
  padding: 15px 20px;
  margin-left: 10px;
}

.btn-add {
  position: relative;
  padding-right: 60px;
}

.btn-add:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../img/plus-icn.svg) no-repeat center center;
  position: absolute;
  right: 15px;
  top: 18px;
}
.ed-btn-default {
  padding: 16px;
  width: 192px;
  text-align: center;
  border-radius: 0;
}
.ed-btn-default:disabled {
  opacity: 0.5;
}
.ed-btn-submit {
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  background: #4d4e8d;
}
.ed-btn-submit:hover {
  color: #ffffff;
}

/* Content Wrap Style start here */
.content-wrap {
  padding: 30px;
  /* max-height: 82vh; */
  overflow: auto;
}
.form-page {
  max-height: 72vh;
}

/* Inner page style start here */
.inner-footer {
  background: #ffffff;
  padding: 20px 50px;
  text-align: center;
}
.row-pd {
  padding: 32px 50px;
}
.ed-cp {
  cursor: pointer;
}
.btn-rarrow {
  position: relative;
  background: #ffffff;
  border: none;
  color: #4d4e8d;
  font-size: 16px;
  padding-right: 50px;
}
.btn-rarrow:after {
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  margin-top: 2px;
  margin-left: 10px;
  background: url("../img/arrow-right-circle.svg") no-repeat;
}
.btn-white {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
}
.btn-status {
  color: #8ab6a1;
  font-size: 11px;
  padding: 4px 8px;
  background: #d4f2e4;
  position: relative;
  padding-right: 16px;
}
.btn-status:after {
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  top: 10px;
  margin-left: 6px;
  background: url("../img/status-dwn-arrow.svg") no-repeat;
}
.ed-bt {
  border-top: 1px solid #eeeeee;
}

.ed-br {
  border-right: 1px solid #eeeeee;
}
.ed-bb {
  border-bottom: 1px solid #eeeeee;
}
.ed-bl {
  border-left: 1px solid #eeeeee;
}

.shadow-blue {
  background-color: #6a7c96;
  height: 9px;
  margin-right: 7px;
  margin-left: 7px;
}

.shadow-green {
  background-color: #8ab6a1;
  height: 8px;
  margin-right: 12px;
  margin-left: 12px;
}

/* Modal styles */
.modal-body {
  max-height: 460px;
  overflow: auto;
}

/* Hide number input spinner */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.pl-max {
  padding-left: 250px;
}

.pl-toggle {
  padding-left: 100px;
}
.shadow-div1 {
  background-color: #8ab6a1;
  height: 9px;
  margin-right: 7px;
  margin-left: 7px;
}

.shadow-div2 {
  background-color: #6a7c96;
  height: 8px;
  margin-right: 12px;
  margin-left: 12px;
}

.editor-content-wrap h1 {
  font-size: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}

.editor-content-wrap h2 {
  font-size: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}

.editor-content-wrap h3 {
  font-size: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}

.editor-content-wrap h4 {
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}

.editor-content-wrap h5 {
  font-size: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}

.editor-content-wrap ul {
  margin-bottom: 20px;
  padding: 0 16px;
}

.editor-content-wrap ul li {
  font-size: 14px;
  color: #000000;
  line-height: 35px;
  list-style: none;
  position: relative;
  padding-left: 10px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.editor-content-wrap ul li:before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  margin-top: 12px;
  margin-left: -26px;
  background: url("../img/start-icn.svg") no-repeat;
}

.custom-select-box input {
  height: 43.8px !important;
}

/* .custom-select-box div.css-1s2u09g-control {
  border: solid 1px #4d4e8d !important;
  border-radius: 0;
} */

.custom-select-box > div:nth-child(3),
.custom-select-box > div:nth-child(3):hover {
  border: solid 1px #4d4e8d !important;
  border-radius: 0;
  padding: 2px;
}
/* .custom-select-box > div {
  min-height: 58px;
} */
/* .custom-select-box .css-qbdosj-Input,
.custom-select-box .css-qbdosj-Input input,
.custom-select-box .css-qbdosj-Input input:focus,
.custom-select-box .css-166bipr-Input,
.custom-select-box .css-166bipr-Input input,
.custom-select-box .css-166bipr-Input input:focus {
  height: 50.8px !important;
  border-radius: 0;
  margin: 0 !important;
} */

.navdropdownwhole .custom-arrow-dropdown {
  position: relative;
  top: 0;
}

.ReactModal__Overlay--after-open {
  z-index: 9999;
}

.ReactModal__Overlay {
  z-index: 9999;
}

.ReactModal__Content {
  max-height: 100%;
  overflow: unset !important;
}

.ReactModal__Content .register-step-content {
  max-height: 530px;
  overflow-y: auto;
}

.et-modal-header {
  margin-bottom: 20px;
}

.et-modal-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #4d4e8d;
}

.et-modal-close {
  all: unset;
  cursor: pointer;
}

.et-modal-body {
  padding: 20px 90px;
}

.et-modal-body h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}

.register-step-btn {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  padding: 14px 60px;
  border: none;
  background: #4d4e8d;
  font-family: "Poppins", sans-serif;
}

.register-step-btn.disabled {
  opacity: 0.27;
}

.no-btn {
  background: #c0c1d6;
}

/* .date-picker {
  position: relative;
} */
/* .clear-icn {
  position: absolute;
  right: 25px;
  top: 50px;
  background: none;
  outline: none;
  border: none;
} */

.de-name {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.de-count {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.cashback {
  border: 1px solid #cccccc;
  width: 100%;
  height: 200px;
  margin-top: 20px;
}

.cashback th {
  border-bottom: 1px solid #cccccc;
  background: #f9fafb;
  color: #b7c1cb;
  padding: 10px;
}

.cashback td {
  text-align: center;
  color: #999999;
}
.form-control.cashback-input {
  border: 1px solid #cccccc !important;
}
.cashback-req-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* student edit form */

.signin-form-row {
  position: relative;
}

.signin-form-row .react-tel-input {
  font-family: "Lato", sans-serif;
  height: 60.8px;
  width: 100%;
}

.signin-form-row .react-tel-input .form-control {
  font-family: "Lato", sans-serif;
  padding-left: 80px;
  border-radius: 0;
  border: solid 1px #4d4e8d;
  height: 60.8px !important;
  width: 100% !important;
}

.signin-form-row .react-tel-input .flag-dropdown {
  padding: 10px !important;
  background: transparent !important;
  border: none !important;
  border-right: 1px solid #4d4e4d !important;
}

.signin-form-row .react-tel-input .flag-dropdown:hover {
  padding: 10px !important;
}

.signin-form-row .react-tel-input .flag-dropdown.open {
  background: transparent !important;
}

.register-step-header-general {
  background-color: #6a7c96;
  padding: 16px 50px;
}

.register-step-header-general .register-step-title {
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.register-step-header-generalnew {
  background-color: #6a7c96;
  padding: 22px 40px;
}

.register-step-header-generalnew .register-step-title {
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.register-step-header {
  background: #dee9df;
  padding: 22px 50px;
}

.register-step-content {
  background-color: #fff;
  padding: 40px 50px;
}

.agt-proof-file {
  position: relative;
  padding-left: 20px;
}

.agt-proof-file:before {
  position: absolute;
  width: 20px;
  height: 20px;
  content: "";
  top: 6px;
  margin-left: -20px;
  background: url("../img/attach-icn.svg") no-repeat;
}

.ref-div {
  height: 1px;
}

.register-step-footernew {
  padding: 20px 46px;
  text-align: right;
  background: #ffffff;
  border-top: 1px solid #eeeeee;
}

.navdropdownwhole .register-step-footernew {
  border: 0px;
}

.bginfocontent {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  padding-top: 27px;
}

.bgbutton {
  margin: 0 5px 0 0;
  width: 49%;
  height: 38%;
  position: relative;
}

.margino {
  margin: 0px;
}

.newtitle {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin: 0px;
}

.newtitle {
  color: #4d4e8d;
}

.register-step-header1 {
  background: #f8fafd;
  padding: 7px 50px;
  color: #000000;
}

.register-step-header1 .titlle,
.newtitle {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin: 0px;
}

.bgclasss {
  background: #fff;
}

.shadow-div {
  background-color: #6a7c96;
  height: 9px;
  margin: 0 7px;
}

.greenshow-div {
  background-color: #8ab6a1;
  height: 8px;
  margin: 0 15px;
}

.date-picker {
  background: url(../img/calendar-icn.svg) no-repeat 95% center #ffffff;
}

.form-label {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #000;
  margin-top: 12px;
}

.tabs-form-label {
  padding-top: 22px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.inner-page-header-agent {
  background: #ffffff;
}

.application-heading {
  font-size: 21px;
  font-weight: 500;
  color: #4d4e8d;
  padding: 4px 3px 0 0px;
  font-family: "Poppins", sans-serif;
}

.register-step-head {
  font-size: 14px;
  color: #4d4e8d;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

.dotbtn,
.dotbtn1 {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dotbtn {
  background-color: #8ab6a1;
  color: #ffffff;
}

.dotbtn1 {
  background-color: #f0f3f8;
}

.endtext {
  text-align: right;
  padding: 0;
}

.inner-title-wrap {
  background: #ffffff;
  padding: 0 30px;
}

.card-title-wrap {
  padding: 12px 26px;
  border-bottom: 1px solid #eeeeee;
}

.pl-toggle .title-wrap,
.pl-toggle .course-nav {
  width: calc(100%);
}

.dottedclsnew {
  height: 65px;
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.bgbutton label,
.bgbutton input {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0;
  border: 1px solid #6a7c96;
}
.bgbutton label:hover {
  border: 1px solid #6a7c96;
}

.bgbutton input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.bgbutton input[type="radio"]:checked + label,
.bgbutton1 input[type="radio"]:checked + label {
  background: #4d4e8d url("../img/TickSquare.svg") no-repeat 95% 50%;
  color: #fff;
}

.bgbutton label {
  cursor: pointer;
  z-index: 90;
  line-height: 2.8em;
  text-align: left;
  background: #ffffff;
  color: #8897ab;
}

.bgbutton:hover label {
  color: #8897ab;
}

.clear-icn {
  position: absolute;
  right: 55px;
  top: 17px;
  background: none;
  outline: none;
  border: none;
}

.file-drag-drop-wrap {
  padding: 40px;
  text-align: center;
  border: 1px dashed #6a7c96;
}

.txt-dec-none {
  text-decoration: none !important;
  color: #000000 !important;
}
