.et-view-content-wrap {
  background: #ffffff;
}

.et-view-title-wrap {
  padding: 20px 45px;
  background: #dee9df;
}

.bg-blue {
  background: #6a7c96;
}

.et-view-primary-title {
  font-size: 18px;
  margin: 0;
  color: #ffffff;
}

.et-view-secondary-title {
  font-size: 16px;
  margin: 0;
  color: #4d4e8d;
}

.et-view-ptitle-link {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  background: url(../img/arrow-right-white-circle.svg) no-repeat right center;
  padding-right: 30px;
}

.et-view-title-link {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #4d4e8d;
  text-decoration: none;
  background: url(../img/arrow-right-circle.svg) no-repeat right center;
  padding-right: 30px;
}

.et-vc--no-info {
  padding: 20px 40px;
}

.et-vc-label {
  font-size: 16px;
  margin-bottom: 5px;
}

.et-vc-info-required {
  color: #8ab6a1;
  margin-bottom: 0;
}

.et-view-inner-link {
  color: #4d4e8d;
  background: url(../img/blue-arrow-right.svg) no-repeat right center;
  padding: 10px 60px 10px 20px;
}

.et-view-edit-link {
  color: #4d4e8d;
  padding: 10px 50px 10px 20px;
}

.et-view-border-bottom {
  border-bottom: 1px #eeeeee solid;
}

.et-view-border-right {
  border-right: 1px #eeeeee solid;
}

.et-vc-list {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.et-list-inner,
.et-vc-step-info {
  padding: 20px 35px;
  position: relative;
}

.et-field-name {
  color: #4d4e8d;
  margin-bottom: 10px;
}

.et-field-value {
  font-size: 16px;
  margin-bottom: 0;
  word-wrap: break-word;
}

.et-vc-step {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4d4e8d;
  background: #dee9df;
  padding: 8px 12px;
  border-radius: 100%;
}

.et-vc-step-title {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4d4e8d;
}

.et-doc-actions {
  position: absolute;
  top: 20px;
  right: 20px;
}

.register-step-header-general {
  background-color: #6a7c96;
  padding: 16px 50px;
}

.register-step-header-general .register-step-title {
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.ed-tabs .nav-link {
  background: #dee2e6;
  color: #666666;
  margin-right: 5px;
}

.ed-tabs .nav-link.active {
  background: #4d4e8d;
  color: #ffffff;
  border: 1px solid #4d4e8d;
}