/* .timeline-wrap .accordion {
  float: none;
  margin: 0;
  position: relative;
}

.timeline-wrap button.accordion-button {
  width: 100%;
  background: transparent;
  color: #4d4e8d;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  padding: 20px 0 20px 82px;
  box-shadow: none;
}

.timeline-wrap button.accordion-button span {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #4d4e8d;
}

.timeline-wrap button.accordion-button span:before {
  content: "";
  width: 4px;
  height: 4px;
  background: #6fc9a8;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  border-radius: 10px;
}

.timeline-wrap .accordion-collapse.collapse,
.timeline-wrap .accordion-collapse.collapsing {
  width: 100%;
  position: relative;
}

.timeline-wrap .accordion-item {
  background: transparent;
  border: none;
  position: relative;
}

.timeline-wrap .accordion-item:after {
  content: "";
  width: calc(100% - 82px);
  height: 1px;
  background: #dee2eb;
  display: block;
  margin-left: auto;
}

.timeline-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  line-height: normal;
  padding-bottom: 20px;
}

.timeline-wrap .accordion-collapse:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #ffffff;
  position: absolute;
  left: 25px;
  bottom: 18px;
}

.timeline-wrap .accordion-button::before {
  background: url(../img/acordion-plus-icn.svg) no-repeat left center;
  position: absolute;
  left: 16px;
}

.timeline-wrap .accordion-button:not(.collapsed)::before {
  background: url(../img/acordion-minus-icn.svg) no-repeat left center;
}

.timeline-wrap .accordion-body {
  padding: 20px 0;
} */

.tab-content-wrap {
  background: #ffffff;
}

.tab-head-wrap {
  margin-bottom: 1px;
}
.tm-card {
  background: #f0f3f8b9;
}
.ststus-title {
  background: #ffffff;
  padding: 7px 30px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #012856;
}

.timeline-col {
  padding: 15px 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.timeline-col-multi {
  min-height: 125px;
}

.timeline-col h5 {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #8ab6a1;
  margin-bottom: 5px;
}

.timeline-col p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  font-size: 12px;
}

.timeline-col a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #4d4e8d;
  text-decoration: underline !important;
}
.timeline-col a:hover {
  text-decoration: underline !important;
}

.ed-tm-status {
  padding: 5px 15px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #ffffff;
  background: #cdcfd2;
  border-radius: 17px;
  display: inline-block;
  margin-bottom: 5px;
}

.tm-green {
  background: #6fc9a8;
}

.tm-yellow {
  background: #efc57b;
}

.tm-red {
  background: #eb6e6e;
}

.tm-date {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #9c9c9c;
}

/* .timeline-row {
  padding-left: 82px;
}

.timeline-row:before {
  width: 52px;
  height: 52px;
  content: "";
  display: inline-block;
  background: url(../img/status-pending-icn.svg) no-repeat center center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
} */

.timeline-row-gray {
  padding-left: 82px;
}

.timeline-row-gray:before {
  width: 52px;
  height: 52px;
  content: "";
  display: inline-block;
  /* background: url(../img/app-dot-icn.svg) no-repeat center center; */
  background: url(../img/timeline-white.svg) no-repeat center center;
  position: absolute;
  top: 0;
  left: 0;
}

.tl-status-complete:before {
  background: url(../img/status-completed-icn.svg) no-repeat center center;
  z-index: 1;
}

.tl-status-complete-negative:before {
  background: url(../img/red_tick.svg) no-repeat center center;
  z-index: 1;
}

.timeline-wrap .accordion-header {
  position: relative;
}

.timeline-wrap .accordion-header:before {
  content: "";
  width: 3px;
  height: 70%;
  background: #ffffff;
  position: absolute;
  left: 25px;
  bottom: 35px;
}

.timeline-wrap .accordion .accordion-item:first-child .accordion-header:before {
  display: none;
}

.missing-info-label {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #ffffff;
  background: #ed5454;
  border-radius: 18px;
  padding: 2px 15px;
  margin-left: 7px;
}

/* .timeline-wrap .accordion .accordion-item:last-child .accordion-collapse:before {
    height: 30%;
  } */

.timeline-wrap .accordion-body .timeline-row:last-child {
  margin-bottom: 15px !important;
}

.timeline-wrap
  .accordion-item:last-child
  .accordion-body
  .timeline-row:last-child:after {
  content: "";
  width: 3px;
  height: 100%;
  background: #f5f6fa;
  position: absolute;
  left: 25px;
  top: 17px;
  display: block;
}

.timeline-row .course-card {
  background: #f5f6fa;
}

.tmh-grey {
  width: 100%;
  text-align: center;
  /* background: #4D4E8D !important; */
  background: #9da4b4 !important;
}

.tmh-green {
  width: 100%;
  text-align: center;
  background: #6fc9a8 !important;
}

.tmh-red {
  width: 100%;
  text-align: center;
  background: #eb6e6e !important;
}

.tmh-yellow {
  width: 100%;
  text-align: center;
  background: #efc57b !important;
}

.clr-white {
  color: #ffffff !important;
}

.tmt-green {
  color: #6fc9a8 !important;
}

.tmt-yellow {
  color: #efc57b !important;
}

.tmt-red {
  color: #eb6e6e !important;
}

.tmt-grey {
  color: #9da4b4 !important;
}

.ag-sub-title {
  background: #ffffff;
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4d4e8d;
  margin-bottom: 0;
}

.app-statusform-wrap label {
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 500;
}
.app-statusform-wrap input[type="email"],
.app-statusform-wrap input[type="password"],
.app-statusform-wrap input[type="text"],
.app-statusform-wrap input[type="date"],
.app-statusform-wrap input[type="datetime-local"] {
  height: 36px;
  padding: 10px;
  border: 1px solid #dee2e6;
  font-size: 14px;
  border-radius: 4px;
}
.app-statusform-wrap .file-upload {
  padding: 7px;
  font-size: 14px;
}
.app-statusform-wrap textarea.form-control {
  height: 36px;
  min-height: 60px;
  padding: 6px 10px;
  font-size: 14px;
}
.apst-react-select__value-container {
  padding: 0 8px !important;
}
.apst-react-select__input-container {
  min-height: 32px !important;
}
.app-statusform-wrap .apst-react-select__input-container input {
  height: 26px;
}
.apst-react-select__menu,
.apst-react-select__placeholder,
.apst-react-select__control {
  font-size: 14px !important;
}
.appst-inrdcr-btn {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: none;
  color: #ffffff;
}
.incr-btn {
  background: #6fc9a8;
}
.dcr-btn {
  background: #ff3b3b;
}
.appst-foot-btn {
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 14px;
  opacity: 0.9;
}
.appst-foot-btn:hover {
  opacity: 1;
}
.appst-foot-btn.add {
  background: #4d4e8d;
}
.appst-foot-btn.save {
  background: #4d4e8d;
}
.appst-foot-btn.cancel {
  background: #b8b8b8;
}
.appst-file-uploaded a {
  font-size: 14px;
}
.appst-txt {
  width: fit-content;
  padding: 4px 16px;
  border-radius: 16px;
  color: #ffffff;
  background: #cacaca;
}
