.form-title {
  font-size: 14px;
  font-weight: 500;
  background: #dee9df;
  padding: 20px 50px;
  color: #4d4e8d;
  margin-bottom: 0;
}
.form-wrap,
.row-wrap {
  padding: 32px 50px;
  background: #ffffff;
  font-family: "Lato", sans-serif;
}
.form-wrap.map {
  padding-bottom: 134px;
}
.form-wrap label {
  font-size: 14px;
  color: #000000;
  padding-bottom: 12px;
}
.form-wrap input[type="text"],
.form-wrap input[type="email"],
.form-wrap input[type="password"],
.form-wrap input[type="number"],
.form-wrap input[type="file"],
.form-wrap textarea,
.form-wrap select {
  padding: 16px 28px;
  border: 1px solid #4d4e8d;
  border-radius: 0;
  /* margin-bottom: 26px; */
}
/* .form-wrap .select-left {
  width: 78%;
} */
.form-wrap .select-right {
  width: 22%;
  border-left: none;
}
.form-wrap .custom-datepicker input {
  padding: 28px;
  width: 100%;
}
.form-wrap .custom-datepicker .rmdp-container {
  width: 100%;
}

.form-wrap input[type="file"] {
  color: #8897ab;
}
.form-wrap input[type="file"]::file-selector-button {
  background: #eaeafa;
  color: #4d4e8d;
  border: none;
  font-size: 15px;
}
.file-drag-drop-wrap {
  padding: 60px;
  text-align: center;
  border: 1px dashed #6a7c96;
  background: #fafafc;
}
.drag-drop-input-lable {
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  color: #000000;
}
.browse-btn {
  background: #eaeafa;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 400;
  color: #4d4e8d;
  border: none;
}
.uploaded-file-wrap {
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  color: #000000;
}
.ed-btn-cancel {
  background: #d4d5eb;
  color: #4d4e8d;
}
.ed-btn-save-dt {
  background: #4d4e8d;
  color: #ffffff;
}
.ed-btn-save-pub {
  background: #8ab6a1;
  color: #ffffff;
}

/* Radio button */
.custom-radio-wrap input {
  display: none;
}
.custom-radio-wrap label {
  width: 200px;
  padding: 16px 28px;
  border: 1px solid #4d4e8d;
  border-radius: 0;
  margin-bottom: 26px;
  color: #8897ab;
  cursor: pointer;
  text-align: center;
}
.custom-radio-wrap input[type="radio"]:checked + label {
  background: #4d4e8d;
  color: #ffffff;
}

/* Checkbox */
.permissions-wrap {
  padding: 0;
}
.permissions-wrap li {
  list-style-type: none;
}
.role-checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.role-checkbox-wrap-popular{
  margin-top: 50px !important;
}
.role-checkbox-wrap input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.role-checkbox-wrap input:checked + .checkmark {
  background: #8ab6a1;
  border: 1px solid #8ab6a1;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 1px solid #6a7c96;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.role-checkbox-wrap input:checked ~ .checkmark:after {
  display: block;
}
.role-checkbox-wrap .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* React Select styles */
.basic-multi-select .css-pk0o0k-control:hover {
  border: 1px solid #4d4e8d;
}