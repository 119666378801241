.btn-blue {
  width: 212px;
  padding: 18px 24px;
  background: #4d4e8d;
  border-radius: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.create-btn-wrap {
  background: #fafafc;
  padding: 34px 42px;
}
.inner-detail-row-head {
  background: #4d4e8d;
  padding: 10px 28px;
}
.inner-detail-row-head label {
  color: #dee9df;
  font-size: 10px;
  padding-bottom: 10px;
}
.inner-detail-row-head p {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 6px;
}
.inner-detail-row-body {
  background: #f3f3ff;
  padding: 10px 28px;
}
.bg-green {
  background: #dee9df;
}
.inner-detail-row-body label {
  font-weight: 600;
  font-size: 10px;
  color: #4d4e8d;
}
.inner-detail-row-body p {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.inner-detail-btn-wrap {
  padding: 10px 0;
  text-align: right;
  margin-right: -14px;
}
.course-name {
  font-weight: 500;
  font-size: 21px;
  color: #4d4e8d;
}
.course-univ {
  padding-top: 10px;
}
.course-univ span {
  font-size: 12px;
  color: #000000;
  background: #dee9df;
  padding: 6px;
  border-radius: 5px;
  margin-right: 15px;
}
.course-fee {
  font-weight: 500;
  font-size: 21px;
  color: #4d4e8d;
}
.course-sem-wrap li {
  list-style-type: none;
}
.sem-edit-icn {
  width: 20px;
  margin-top: -10px;
}
.sem-del-icn {
  width: 15px;
  margin-top: -10px;
}
.course-month-wrap {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
}
.course-sem-month {
  background: #dee9df;
  padding: 2px 10px 4px 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Changed UI */
.border-left-right {
  border-left: 1px solid #f0f3f8;
  border-right: 1px solid #f0f3f8;
}
.course-logo {
  width: 40px;
  height: 40px;
}
.course-title-wrap {
  background: #ffffff;
  padding: 0 30px !important;
  z-index: 1;
}
.inner-page-title-sm {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6a7c96;
}
.inner-page-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4d4e8d;
}
.course-card-footer {
  padding: 4px 14px;
  background: #4d4e8d;
}
.course-card-footer-pd {
  padding: 16px 14px;
}
.course-inner-row.body label {
  font-weight: 600;
  font-size: 10px;
  color: #4d4e8d;
}
.course-card-footer .amount {
  font-size: 16px;
  font-weight: 500;
}
.course-card-footer .apply-now {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.course-nav {
  list-style: none;
  display: flex;
  padding: 30px;
  margin: 0 -30px;
  background: rgba(240, 243, 248, 0.95);
  z-index: 1;
  top: 155px;
  position: fixed;
  right: 37px;
  width: 82.2%;
}

.course-nav li {
  list-style: none;
  color: #acacce;
  padding: 0px 20px;
  cursor: pointer;
}

.course-nav li:first-child {
  padding-left: 0;
}

.course-nav li a {
  text-decoration: none;
  color: #acacce;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.course-nav li.active a {
  color: #4d4e8d !important;
  padding: 0px;
  position: relative;
}
.course-nav li.active a:after {
  position: absolute;
  content: "";
  width: 80%;
  height: 3px;
  bottom: -10px;
  left: 10%;
  background: #4d4e8d;
}

.course-inner-row {
  padding: 10px 28px;
}
.course-inner-row.head {
  background: #4d4e8d;
  color: #ffffff;
}
.course-inner-row.body {
  background: #f3f3f3;
}
.course-inner-row.body p {
  font-weight: 600;
  margin-bottom: 4px;
}
.course-inner-row.body label {
  font-weight: 600;
  font-size: 10px;
  color: #4d4e8d;
}
.content-col {
  padding: 40px 60px;
  font-size: 16px;
  background: #ffffff;
  font-family: "Lato", sans-serif;
}
.content-col p {
  line-height: 26px;
}
.filter-cards-title {
  font-size: 14px;
  color: #8ab6a1;
  margin-bottom: 5px;
}
.filter-cards-title.newhead {
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
.course-lc {
  background-color: #4d4e8d;
  padding: 15px;
  margin-bottom: 15px;
}

.course-lc .head-small {
  color: #dee9df;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0;
}

.course-lc .head-big {
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 4px;
}
.header-col {
  background: #6a7c96;
  color: #ffffff;
  padding: 18px 60px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}
.course-modules-wrap {
  margin: 20px 0;
}
.course-modules-wrap .module-header {
  background: #4d4e8d;
  padding: 16px 20px;
  color: #ffffff;
}
.course-modulesstep2 .content {
  margin: 0px 0px 10px 0px;
}
.course-modules-wrap .content {
  padding: 16px 20px;
  font-size: 13px;
  background: #dee9df;
}
.module-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}
.module-p {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 0;
}
.module-content-p {
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}
.checkbox-gray input[type="checkbox"] {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  border-radius: 6px 6px 6px 6px;
  background: #eff2f7;
  cursor: pointer;
}

.checkbox-gray input[type="checkbox"]:checked {
  width: 22px;
  height: 22px;
  margin-top: -2px;
  content: "";
  background: url("../img/tick-gray-icn.svg");
  border-radius: 6px 6px 6px 6px;
}
.tabs-p {
  padding: 16px 40px;
  border-right: 1px solid #98adca;
}
.tabs-p1 {
  font-size: 16px;
  font-family: "Lato", sans-serif;
}
.course-bottom-wrap {
  color: #ffffff;
  background: #6a7c96;
}
.course-bottom-wrap a {
  color: #ffffff;
  cursor: pointer;
  padding: 30px 60px;
  line-height: 30px;
  text-decoration: none;
}
.course-bottom-wrap1 {
  padding: 16px 60px;
  color: #ffffff;
  background: #6a7c96;
}
.course-bottom-wrap3 {
  background: #4d4e8d;
  padding: 30px;
}
.course-module-header-p {
  font-size: 12px;
  margin-bottom: 10px;
  font-family: "Lato", sans-serif;
}

.pl-toggle .course-nav {
  width: 92%;
}

.header-col p, .header-col h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.et-course-intakes {
  background: url(../img/calendar-icn.svg) no-repeat 20px center #F3F3FF;
  padding: 17px 30px 17px 50px;
  min-width: 150px;
  color: #4D4E8D;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}