.header-logo {
  /* width: 60px; */
  width: 52px;
}

.img-fluid-process {
  width: 25%;
  height: auto;
}

.cp {
  cursor: pointer !important;
}

p.error-txt {
  margin-top: 0px;
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
}

/* Text Editor style */
.wrapper-class {
  padding: 1rem;
  border: 1px solid #4d4e8d;
}

.editor-class {
  /* background-color:lightgray; */
  padding: 1rem;
  /* border: 1px solid #ccc; */
}

.toolbar-class {
  border: 1px solid #ccc;
}

.autocomplete-dropdown-container {
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 62px;
  left: 0;
  z-index: 9999;
  background: #ffffff;
}

.rdw-dropdown-selectedtext,
.rdw-dropdown-selectedtext:hover {
  text-decoration: none;
  color: #000000;
}

.et-show {
  display: block;
}

.et-hide {
  display: none;
}

.et-link {
  cursor: pointer;
  text-decoration: underline;
  color: #4d4e8d;
}

/* application status color code */
.et_status_fees_payment {
  background-color: #f4edff;
  color: #9d7bce;
}

.et_status_review {
  background-color: #e7fbf1;
  color: #8ab6a1;
}

.et_status_missing_information {
  background-color: #f2c9f2;
  color: #9c039c;
}

.et_status_course_suggestion {
  background-color: #e3f7ff;
  color: #49bbec;
}

.et_status_update {
  background-color: #fef2db;
  color: #fdb426;
}

.et_status_course_payment_interview {
  background-color: #fef2db;
  color: #fd8e26;
}

.et_status_course_payment_docs {
  background-color: #fef2db;
  color: #bdf07c;
}

.et_status_accepted {
  background-color: #effad9;
  color: #b2ed3d;
}

.et_status_visa {
  background-color: #ffefe3;
  color: #f9ad74;
}

.et_status_review_missing_info {
  background-color: #f2c9f2;
  color: #9c039c;
}

.et_status_submitted_to_university {
  background-color: #e3f7ff;
  color: #49bbec;
}

.et_status_conditional_offer,
.et_status_conditional_offer_letter,
.et_status_conditional_offer_missing_info,
.et_status_conditional_offer_interview,
.et_status_conditional_offer_mock,
.et_status_sfe_process,
.et_status_unconditional_offer {
  background-color: #fef2db;
  color: #fdb426;
}

.et_status_course_payment {
  background-color: #ffeaf9;
  color: #d876bd;
}

.et_status_interview {
  background-color: #fef2db;
  color: #d99918;
}

.et_status_payment_pending {
  background-color: #fef2db;
  color: #fdb426;
}

.et_status_document_request {
  background-color: #effad9;
  color: #96bd45;
}

.et_status_document_upload {
  background-color: #ffefe3;
  color: #f9ad74;
}

.et_status_enrolled {
  background-color: #f0f0ff;
  color: #4d4e8d;
}

.et_status_rejected {
  background-color: #ffe7e5;
  color: #fd746b;
}

.et_status_cancelled {
  background-color: #f1f1f1;
  color: #000000;
}

/* status end */
.et-logo {
  width: 25%;
  height: auto;
}
/*File Input custom style*/
.custom-file-input {
  border: 1px solid #4d4e8d;
  padding: 10px 28px;
}
.custom-file-input .file-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 344px;
  padding: 5px;
}
.file-upload-inp {
  position: relative;
}
.img-video-name {
  position: absolute;
  left: 127px;
  top: 16px;
}
.verify-btn {
  background: #6a7c96 !important;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #ffffff !important;
  padding: 0px 20px;
  margin-left: 10px;
  border-radius: 5px;
}

.header {
  z-index: 1049 !important;
}
.popover-header {
  line-height: 18px;
}
.popover-body a {
  text-decoration: none !important;
}
.app-status-card {
  position: relative;
}
.app-status-tab {
  margin-top: -20px;
}
.app-status-card .btn-refresh {
  width: 34px;
  height: 34px;
  padding: 4px;
  color: #4d4e8d;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
}
.app-status-card .btn-refresh:hover {
  color: #ffffff;
  background: #4d4e8d;
}

.custom-arrow-dropdown .header-username {
  color: #4d4e8d;
  text-decoration: none;
}
.custom-arrow-dropdown .dropdown-toggle,
.custom-arrow-dropdown a {
  text-decoration: none !important;
}
.rsuite-inputs {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
}
.rsuite-inputs .rs-picker-input-group {
  border: none !important;
  padding: 6px;
}
.date-slot-row {
  border-bottom: 1px solid #eeeeee;
}
.date-slot-wrap {
  display: flex;
  flex-wrap: wrap;
}
.time-slot-wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
}
.selected-time-slot {
  padding: 4px 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #ececec;
}
.selected-time-slot a {
  color: #cccccc;
  text-decoration: none !important;
}
.selected-time-slot a:hover {
  color: rgb(221, 41, 41);
}
.interview-datetime-col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 6px;
}
.interview-datetime-col .interview-date {
  padding: 2px 10px;
  border-radius: 4px;
  position: relative;
}
.interview-datetime-col .interview-date::before {
  content: "";
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #00c22a;
  position: absolute;
}
.interview-datetime-col .interview-time {
  font-size: 12px;
  padding: 2px 10px;
  margin-bottom: 3px;
  border-radius: 4px;
  background: #eeeeef;
}
.condition-edit-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}
.category-delete-btn {
  width: 30px;
  height: 30px;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  border-radius: 50%;
}
.category-delete-btn:hover {
  background: rgb(233, 60, 60);
}
.category-delete-btn:hover img {
  filter: invert(100%);
}
