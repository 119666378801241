.modal-header {
  background: #dee9df;
  padding: 22px 50px;
}
.modal-title {
  font-weight: 500;
  font-size: 16px;
  color: #4d4e8d;
}
.modal-body {
  background: #ffffff;
  min-height: 54vh;
}
.modal-footer {
  background: #ffffff;
  padding: 20px 50px;
}
