.et-page-wrap {
    background: url(../img/edu-tech-background.png);
    height: 100vh;
    background-size: cover;
    z-index: 0;
  }

  .box-header {
    padding: 30px 60px;
    background: #6a7c96;
  }
  
  .box-header .title {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .box-body {
    padding: 22px 60px;
    background: #ffffff;
  }
  
  .et-form-row {
    position: relative;
  }
  
  .box-body input[type="email"],
  .box-body input[type="password"],
  .box-body input[type="text"] {
    padding: 20px 25px;
    border: solid 1px #4d4e8d;
    border-radius: 0;
    height: 60.8px;
    width: 100%;
  }
  
  .form-label {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    color: #000;
  }
  
  .signin-details-wrap {
    background-color: #fff;
    position: relative;
    height: 550px;
  }
  
  .email-inp {
    position: relative;
  }
  
  .email-fieldcheckbox {
    position: absolute;
    right: 20px;
    top: 18px;
  }
  
  .password-fieldcheckbox {
    position: absolute;
    right: 69px;
    top: 181px;
  }
  
  .checkbox-design input[type="checkbox"] {
    width: 22px;
    height: 22px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 6px 6px 6px 6px;
    background: #eff2f7;
    cursor: pointer;
  }
  
  .checkbox-design input[type="checkbox"]:checked {
    width: 22px;
    height: 22px;
    content: "";
    background: url("../img/checkbox-active.svg");
    border-radius: 6px 6px 6px 6px;
  }
  
  .btn-signin,
  .btn-processing {
    border-color: transparent;
    background-color: #4d4e8d;
    color: #fff;
    font-size: 15px;
    width: 192px;
    height: 59px;
  }
  
  .btn-signin-disable {
    background-color: #6a7c96;
    border: none;
    font-size: 15px;
    color: #cccccc;
    width: 192px;
    height: 59px;
    position: relative;
  }
  
  .btn-signin-disable:before {
    width: 30px;
    height: 30px;
    content: "";
    position: absolute;
    left: 10px;
    top: 13px;
    background: url("../img/btn-spinner.gif") no-repeat;
    background-size: 30px 30px;
  }
  
  .btn-processing:before {
    width: 30px;
    height: 30px;
    content: "";
    position: absolute;
    left: 80px;
    top: 13px;
    background: url("../img/btn-spinner.gif") no-repeat;
    background-size: 30px 30px;
  }
  
  .btn-default {
    border-color: transparent;
    background-color: #4d4e8d;
    color: #fff;
    font-size: 15px;
    width: 192px;
    height: 59px;
  }
  
  .btn-default:disabled {
    background-color: #6a7c96;
    border: none;
    position: relative;
  }
  
  .btn-default:disabled::before {
    width: 30px;
    height: 30px;
    content: "";
    position: absolute;
    left: 10px;
    top: 13px;
    background: url("../img/btn-spinner.gif") no-repeat;
    background-size: 30px 30px;
  }
  
  .error-txt {
    color: #ff0000;
  }
  
  .rememberlogin-text {
    font-size: 15px;
    font-family: "Lato", sans-serif;
    color: #000;
  }
  
  .et-cp {
    cursor: pointer;
  }
  
  .btn-register {
    padding: 20px 30px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #000;
    background-color: #fff;
    border: solid 1px #4d4e8d;
  }
  
  .et-page-wrap-border {
    border-top: solid 1px #eee;
  }
  
  .shadow-div {
    background-color: #6a7c96;
    height: 9px;
    margin-right: 7px;
    margin-left: 7px;
  }
  
  .greenshow-div {
    background-color: #8ab6a1;
    height: 8px;
    margin-right: 12px;
    margin-left: 12px;
  }

  .pwd-eye {
    position: absolute;
    right: 30px;
    top: 16px;
  }

  .ed-custom-tm {
    margin-top: 16vh;
  }