.univ-detail-wrap {
  background: #ffffff;
  font-family: "Lato", sans-serif;
}
.univ-view-img {
  padding: 14px 17px;
  border: 1px solid #eeeeee;
}
.univ-name {
  font-size: 21px;
  color: #000000;
}
.univ-place {
  font-weight: 400;
  font-size: 16px;
  color: #6a7c96;
  margin-bottom: 0;
}
.status-txt,
.app-label {
  font-size: 14px;
  color: #8ab6a1;
  margin-bottom: 6px;
}
.app-desc {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.course-desc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.personal-detail-p {
  margin: 0;
  padding: 30px 0;
}
.univ-detail-label {
  font-size: 13px;
  color: #8ab6a1 !important;
}
.univ-details-txt {
  font-size: 16px;
  color: #000000;
  padding-bottom: 30px;
}
.univ-icn-p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.univ-icn-p span {
  margin-left: 20px;
}
.univ-cust-pd {
  padding-left: 50px;
}

.univ-slider .slick-next {
  top: 120px;
  right: 28px;
  z-index: 1;
}

.univ-slider .slick-prev {
  top: 120px;
  left: 28px;
  z-index: 1;
}

.univ-slider {
  width: 100%;
  position: relative;
}
.univ-slider .univ-img {
  width: 100% !important;
  height: 335px;
}
.btn-view-photo {
  position: absolute;
  right: 24px;
  bottom: 26px;
  color: #4d4e8d;
  border-radius: 0;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}
.univ-detail-p {
  font-size: 14px !important;
  color: #000000 !important;
  line-height: 25px !important;
  font-weight: 400 !important;
}
.p-link,
.p-link:hover {
  color: #8ab6a1;
  text-decoration: none;
}
.univ-sub-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 20px 0 10px 0;
}
.univ-detail-li {
  margin: 0;
  padding: 0 16px;
}
.univ-detail-li li {
  font-size: 14px;
  color: #000000;
  line-height: 35px;
  list-style: none;
  position: relative;
  padding-left: 10px;
}
.univ-detail-li li:before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  margin-top: 12px;
  margin-left: -26px;
  background: url("../img/start-icn.svg") no-repeat;
}
