/*university steps starts here */
.univ-detail-wrap {
  background: #ffffff;
  font-family: "Lato", sans-serif;
}

.univ-view-img {
  padding: 12px 17px;
  border: 1px solid #eeeeee;
  height: 63px;
  min-width: 53px;
}

.univ-name {
  font-size: 21px;
  color: #000000;
}

.univ-place {
  font-weight: 400;
  font-size: 16px;
  color: #6a7c96;
  margin-bottom: 0;
}

.status-txt {
  font-size: 14px;
  color: #8ab6a1;
  margin-bottom: 6px;
}

.btn-univ-status {
  color: #8ab6a1;
  font-size: 11px;
  padding: 4px 8px;
  background: #d4f2e4;
  position: relative;
  padding-right: 16px;
}

.btn-univ-status:after {
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  top: 10px;
  margin-left: 6px;
  background: url("../img/status-dwn-arrow.svg") no-repeat;
}

.univ-detail-label {
  font-size: 13px;
  color: #8ab6a1 !important;
}

.univ-details-txt {
  font-size: 16px;
  color: #000000;
  padding-bottom: 30px;
}

.univ-icn-p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.univ-icn-p span {
  margin-left: 20px;
}

.univ-slider .slick-next {
  top: 210px;
  right: 28px;
  z-index: 1;
}

.univ-slider .slick-prev {
  top: 210px;
  left: 28px;
  z-index: 1;
}

.univ-slider {
  position: relative;
}

.univ-slider .univ-img {
  width: 100%;
  height: 400px;
}
.univ-slider .carousel-indicators {
  display: none;
}
.univ-slider .carousel-control-prev-icon {
  background-image: url("../img/larrow.png");
}
.univ-slider .carousel-control-next-icon {
  background-image: url("../img/rarrow.png");
}

.btn-view-photo {
  position: absolute;
  right: 24px;
  bottom: 26px;
  color: #4d4e8d;
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}
.btn-view-photo:hover {
  background: rgba(255, 255, 255, 0.8);
}

.univ-sub-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 20px 0 10px 0;
}

.univ-detail-li {
  margin: 0;
  padding: 0 16px;
}

.univ-detail-li li {
  font-size: 14px;
  color: #000000;
  line-height: 35px;
  list-style: none;
  position: relative;
  padding-left: 10px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.univ-detail-li li:before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  margin-top: 12px;
  margin-left: -26px;
  background: url("../img/start-icn.svg") no-repeat;
}

.et-border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.univ-slider-step3 .univ-img-step3 {
  height: 439px;
  width: 100% !important;
}

.row-padding {
  padding: 25px 54px;
}

.univ-details-text {
  font-size: 16px;
  color: #000000;
  padding-bottom: 10px;
}

.row-padding-icons {
  padding: 30px 13px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.form-title-step3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  background: #6a7c96;
  padding: 20px 50px;
  margin-bottom: 0px;
  color: #ffffff;
}

.row-wrap-step3 {
  padding: 32px 50px;
  background: #ffffff;
  font-family: "Lato", sans-serif;
}

.right-content-width {
  /* position:relative;  */
  background-color: #6a7c96;
}

.right-content-width.empty {
  height: 40px;
  background: #ffffff;
}

.right-content-width .slick-prev:before {
  content: url(../img/slick-left-arrow.svg);
}
.right-content-width .slick-next:before {
  content: url(../img/slick-right-arrow.svg);
}
.right-content-width .slick-dots {
  bottom: -31px;

  position: absolute;

  width: 100%;
    padding: 1px;
    margin: 0px;
    list-style: none !important;
    text-align: center  !important;
}
.right-content-width .slick-dots li {
  width: 70px;

  
  display: inline-block;
  padding: 0;
    cursor: pointer;
    height:21px;
}
.right-content-width .slick-dots li button:before {
  width: 70px !important;
}
.right-content-width .slick-dots ul button{
  padding: 0 !important;
}

.right-content-width .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: inline;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.card-slider {
  position: relative;
  /* margin: 60px auto; */
  padding-bottom: 50px;
  /* width: 1200px; */
}

.card-margin {
  margin-bottom: 53px;
}

.card-slider .slick-list {
  margin: 0 -7px !important;
}

.card-slider .slick-slide > div {
  padding: 0 42px !important;
}

.card-slider .slick-prev {
  top: unset;
  bottom: 0;
  height: 0;
}

.card-slider .slick-next {
  top: unset;
  bottom: 0;
  height: 0;
}

.courses-slider-wrap .slick-dots li {
  /* margin: 0; */
  display:inline;
  margin:-9px;
  
}

.courses-slider-wrap .slick-track{
  display:flex;
}

/* .courses-slider-wrap .slick-dots li button {
    width: 100% !important;
  } */
.courses-slider-wrap .slick-dots li button:before {
  content: "" !important;
  height: 3px !important;
  background-color: #000000;
}

/* .courses-slider-wrap .slick-dots li.slick-active button:before {
  background-color: #8ab6a1;
} */

.courses-slider-wrap .slick-prev {
  margin-left: 30%;
  z-index: 1;

  font-size: 0;
  line-height: 0;
  position: absolute;
 
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  bottom: -27px;
}

.courses-slider-wrap .slick-next {
  margin-right: 30%;
  z-index: 1;

  font-size: 0;
  line-height: 0;
  position: absolute;

  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  bottom: -27px;
    right: 0;
}
.courses-slider-wrap .slick-slider{
  position: relative;
}

.course-card {
  margin-top: 30px;
  background: #ffffff;
}

.course-card-header {
  padding: 16px;
  /* min-height: 120px; */
}

.card-course-title-link {
  text-decoration: none;
}

.card-course-title {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.course-tag {
  background: #efc57b;
  padding: 3px 14px;
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
}

.bg-gold {
  background: #f9ad74;
}

.course-card-university-wrap {
  padding: 14px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.course-card-university-wrap img {
  width: 60px;
  padding: 10px 13px;
  border: 1px solid #eeeeee;
}

.card-university-name {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  margin-bottom: 0;
  color: #000000;
  text-decoration: none;
}

.card-course-desc {
  min-height: 100px;
}

.card-course-desc p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0;
  padding: 14px;
}

.course-card-footer p {
  margin-bottom: 0;
  color: #ffffff;
}

.course-card-footer .label {
  font-size: 14px;
}

.course-card-footer .amount {
  font-size: 16px;
  font-weight: 500;
}

.course-card-footer .apply-now {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.apply-success {
  font-size: 16px;
  color: #2be731 !important;
}

.university-card-logo-wrap {
  width: 100%;
}

.university-card-logo-wrap img {
  width: 25%;
}

.br-white {
  height: 30px;
  background: #ffffff;
}

.card-university-title {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.card-university-place {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #8ab6a1;
}

.et-view-ptitle-link {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  background: url(../img/arrow-right-white-circle.svg) no-repeat right center;
  padding-right: 30px;
}

.et-view-title-link {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #4d4e8d;
  text-decoration: none;
  background: url(../img/arrow-right-circle.svg) no-repeat right center;
  padding-right: 30px;
}

.inner-page-header-title {
  font-size: 21px;
  font-weight: 500;
  color: #4d4e8d;
}

.university-header .course-tag, .university-header .course-tag .bg-gold {
  color: #4d4e8d;
  background: #f0f3f8;
  border: 1px solid #ababd8;
}