.std-detail-wrap {
  background: #ffffff;
  font-family: "Lato", sans-serif;
}
.std-name {
  font-weight: 500;
  font-size: 21px;
  color: #4d4e8d;
}
.row-title-wrap {
  padding: 20px 50px;
  background: #DEE9DF;
}
.row-title {
  font-size: 16px;
  font-weight: 500;
  color: #4d4e8d;
  margin-bottom: 0;
}
.row-sub-title-wrap {
  background: #EFF5F0;
}
.row-wrap {
  padding: 32px 50px;
  background: #ffffff;
  font-family: "Lato", sans-serif;
}
.row-wrap label {
  font-size: 14px;
  color: #000000;
  padding-bottom: 12px;
}

.et-vc-info {
  background: #ffffff;
}
.et-progress {
  width: 80%;
  height: 8px;
}
.et-progress.progress-high .progress-bar{
  background-color: #6FC9A8;
}
.et-progress.progress-medium .progress-bar{
  background-color: #EFC57B;
}
.et-progress.progress-low .progress-bar{
  background-color: #FD746B;
}
.et-vc-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.et-list-inner,
.et-vc-step-info {
  padding: 35px 50px;
  background: #ffffff;
}

.et-field-name {
  color: #4d4e8d;
  font-size: 14px;
  margin-bottom: 10px;
}

.et-field-value {
  font-size: 16px;
  margin-bottom: 0;
}

.et-vc-step {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4d4e8d;
  background: #dee9df;
  padding: 8px 12px;
  border-radius: 100%;
}

.et-vc-step-title {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4d4e8d;
}

.et-vc-footer {
  padding: 35px 50px;
  background: #ffffff;
  border-top: 1px solid #eeeeee;
}
.et-footer-txt,
.et-footer-txt a {
  color: #5A6A7E;
  font-size: 14px;
}
